export default {
  firstHeading: 'מימון קצר',
  firstText: [
    'הלוואות לגישור, הון חוזר, מימון ייבוא',
    'ועוד צרכים עסקיים קצרים. באולטרה',
    'מציעים עד מליון שקל לתקופה של',
    'עד 12 חודשים בתנאים התפורים',
    'בדיוק לעסק שלך',
  ],
  coverHeading: 'אל תחנקו את התזרים של העסק',
  coverText:
    'מגוון צרכים קצרי טווח של העסק דורשים פתרון מימוני זהה. בין אם אתה יבואן שצריך לשלם על סחורה 4 חודשים לפני שאתה מוכר אותה או קבלן שמקבל תשלום בשוטף+60 אבל צריך לשלם משכורות כבר החודש. באולטרה מבינים את הצרכים העסקיים שלכם ומציעים מגוון פתרונות מימון קצרי מועד שמחזקים את התזרים ומשאירים את העסק שלך עם מזומן לפעילות. קחו אוויר, אנחנו איתכם',
  bannerGridTextOne: 'הליך פשוט',
  bannerGridHeadingOne: '15 דקות',
  bannerGridTextTwo: 'העברת סכום ההלוואה',
  bannerGridHeadingTwo: '48 שעות',
  bannerGridTextThree: 'ריבית שנתית החל מ-',
  bannerGridHeadingThree: 'פריים + 4%',
  bannerGridTextFour: 'הלוואות עד-',
  bannerGridHeadingFour: '1 מליון שח',
  gridItemOneHeading: 'פשוט',
  gridItemOneText:
    'אין צורך להיכנס להתניות מימון מורכבות או ניתוח עסקי מסובך. מימון קצר מצריך בדיקות ספורות. גם התפעול שלו פשוט. בד״כ משלמים רק ריבית חודשית ואת הקרן בסוף התקופה.',
  gridItemTwoHeading: 'מדויק',
  gridItemTwoText:
    'מימון קצר לנושא ספציפי מאפשר להבין את העלות הנקודתית ולהצמיד אותה למהות המימון. נניח, כמה להוסיף לעלות מוצרים שנמכרים באמצעות מימון הייבוא',
  gridItemThreeHeading: 'זול',
  gridItemThreeText:
    'באופן יחסי מימון קצר זול ממימון ארוך בגלל שתי סיבות. הסיכון במימון קצר קטן יותר ומשך הזמן עליו משלמים ריבית קצר יותר',
  gridItemFourHeading: 'מתאים',
  gridItemFourText:
    'מימון קצר שמשקף צורך עסקי ברור מתאים יותר לפעילות העסק. כך לדוגמה אפשר ליצור בטחונות למרות שש״כ, לאפשר תזרים חיובי עד סילוק המימון או לצמצם אובליגו בהתאם לעונתיות.',
  carouselHeading: 'מוצרי אשראי לדוגמה',
  carouselTextOne:
    'יבואן מזון, הלקוחות חונקים אותך עם שוטף+60? הספקים דורשים שתשלם cif ואתה חייב להמשיך לגלגל את המשלוחים? הסחורה שלך יכולה להיות בטוחה. קח הלוואת למימון הייבוא ותמשיך להגדיל היקפים.',
  carouselTextTwo:
    'שיפוצניק, לקחת פרוייקט חדש? מאברוק. במקום להילחץ ולריב עם הלקוח על מקדמות קח הלוואה להצטיידות ולך לעבוד בראש שקט',
  carouselTextThree: 'מבול של הוצאות והחזר המע״מ רחוק? קבל מימון מדויק בגובה ההחזר עד לתאריך הזיכוי',
};
