import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import styles from './shortTerm.module.css';
import TEXTS from './hebrewTexts';
import ContactSection from '../ContactSection/contactSession';
import SvgIcons from '../CompanyPage/SvgIcons';
import { Carousel } from 'react-bootstrap';
const ShortTermInfo = (props: any) => {
  return (
    <div className={styles.root}>
      <div className={styles.coverBlock}>
        <img src='/assets/images/short_finance_cover.png' />
        <div className={styles.coverBlockText}>
          <h3>{TEXTS.firstHeading}</h3>
          {TEXTS.firstText.map((item) => (
            <p dir='rtl'>{item}</p>
          ))}
        </div>
      </div>
      <div className={styles.coverBanner}>
        <div className={styles.coverBannerGrid}>
          <div className={styles.coverBannerGridItem}>
            <p dir='rtl'>{TEXTS.bannerGridTextOne}</p>
            <h3 dir='rtl'>{TEXTS.bannerGridHeadingOne}</h3>
          </div>
          <div className={styles.coverBannerGridItem}>
            <p dir='rtl'>{TEXTS.bannerGridTextTwo}</p>
            <h3 dir='rtl'>{TEXTS.bannerGridHeadingTwo}</h3>
          </div>
          <div className={styles.coverBannerGridItem}>
            <p dir='rtl'>{TEXTS.bannerGridTextThree}</p>
            <h3 dir='rtl'>{TEXTS.bannerGridHeadingThree}</h3>
          </div>
          <div className={styles.coverBannerGridItem}>
            <p dir='rtl'>{TEXTS.bannerGridTextFour}</p>
            <h3 dir='rtl'>{TEXTS.bannerGridHeadingFour}</h3>
          </div>
        </div>
      </div>
      <div className={styles.textCover}>
        <h3>{TEXTS.coverHeading}</h3>
        <p dir='rtl'>{TEXTS.coverText}</p>
      </div>
      <div className={styles.gridWrap}>
        <div className={styles.gridItem}>
          <div className={styles.gridItemTextWrap}>
            <h3>{TEXTS.gridItemOneHeading}</h3>
            <p dir='rtl'>{TEXTS.gridItemOneText}</p>
          </div>
          <div className={styles.gridItemSvgWrap}>{SvgIcons.tick}</div>
        </div>
        <div className={styles.gridItem}>
          <div className={styles.gridItemTextWrap}>
            <h3>{TEXTS.gridItemTwoHeading}</h3>
            <p dir='rtl'>{TEXTS.gridItemTwoText}</p>
          </div>
          <div className={styles.gridItemSvgWrap}>{SvgIcons.tick}</div>
        </div>
        <div className={styles.gridItem}>
          <div className={styles.gridItemTextWrap}>
            <h3>{TEXTS.gridItemThreeHeading}</h3>
            <p dir='rtl'>{TEXTS.gridItemThreeText}</p>
          </div>
          <div className={styles.gridItemSvgWrap}>{SvgIcons.tick}</div>
        </div>
        <div className={styles.gridItem}>
          <div className={styles.gridItemTextWrap}>
            <h3>{TEXTS.gridItemFourHeading}</h3>
            <p dir='rtl'>{TEXTS.gridItemFourText}</p>
          </div>
          <div className={styles.gridItemSvgWrap}>{SvgIcons.tick}</div>
        </div>
      </div>
      <h3 className={styles.carouselHeading}>{TEXTS.carouselHeading}</h3>
      <div className={styles.carousel}>
        <Carousel>
          <Carousel.Item>
            <img src='/assets/images/carousel-grey.png' className={styles.carouselImage} />
            <Carousel.Caption className={styles.carouselText}>
              <h3 dir='rtl'>{TEXTS.carouselTextOne}</h3>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img src='/assets/images/carousel-grey.png' className={styles.carouselImage} />
            <Carousel.Caption className={styles.carouselText}>
              <h3 dir='rtl'>{TEXTS.carouselTextTwo}</h3>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img src='/assets/images/carousel-grey.png' className={styles.carouselImage} />
            <Carousel.Caption className={styles.carouselText}>
              <h3 dir='rtl'>{TEXTS.carouselTextThree}</h3>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </div>
      <ContactSection link='/assets/images/short_finance_contact.png' />
    </div>
  );
};

export default ShortTermInfo;
